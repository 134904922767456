import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseURLImg } from "../../api/api";
import { useSelector } from "react-redux";
import { PermissionContext } from "../layout/user.provider";

const CountryFlags = ({ countryName }) => {
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const { countryList } = useContext(PermissionContext);

  useEffect(() => {
    if (countryName && countryList.length > 0) {
      // Fetch the data for the specific country

      const foundCountry = countryList.find(
        (item) => item.name.toLowerCase() === countryName.toLowerCase()
      );
      if (foundCountry && foundCountry?.flagFileName) {
        const replaceFlagName = foundCountry?.flagFileName.replace(
          ".png",
          "_flag-jpg-xs.jpg"
        );
        foundCountry["flagFileName"] = replaceFlagName;
      }
      setCountry(foundCountry || null);
      setLoading(false);
    } else {
      setLoading(false); // If no countryName is provided, stop loading
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ textAlign: "left" }}>
      {country ? (
        <>
          <img
            src={`${baseURLImg}/flag-jpg-xs/${country.flagFileName}`}
            alt={`${country.name} flag`}
            style={{
              width: "2em",
              height: "2em",
            }}
          />
        </>
      ) : (
        <span> </span>
      )}
    </div>
  );
};

export default CountryFlags;
