import "./App.css";
import { IonApp } from "@ionic/react";
import { ToastContainer } from "react-toastify";
import React from "react";
import CheckUpdate from "./utils/checkUpdate";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "./routes";
import { Suspense } from "react";


const App = () => {

   React.useEffect(() => {
     window.addEventListener("error", (e) => {
       if (e.message.includes("ChunkLoadError")) {
         alert("Please reload your window!");
       }
     });
   }, []);
   
  return (
    <IonApp>
      <CheckUpdate />
      <Suspense fallback={"Loading..."}>
        <RouterProvider router={createBrowserRouter(routes)} />
      </Suspense>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </IonApp>
  );
};

export default App;
