import React from "react";
// import GoogleMap from "google-map-react";
import pin from "../../../images/map-pin.svg";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const Marker = (props) => {
  const { name, lat, lng } = props;
  return (
    <div
      className="pin"
      style={{ backgroundColor: "transparent", cursor: "pointer" }}
      title={name}
      onClick={() => {
        const url = `https://www.google.com/maps/dir//${lat},${lng}`;
        window.open(url, "_blank");
      }}
    >
      <img alt="pin" src={pin} />
    </div>
  );
};

export default function SimpleMap(props) {
  const [mark, setMark] = React.useState({});
  const [center, setCenter] = React.useState({ lat: 31, lng: 34 });
  const [searchValue, setSearchValue] = React.useState(null);

  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const mapRef = React.useRef(null);

  React.useEffect(() => {
    console.log("<SimpleMap> props?.markerPos", props?.markerPos);
    if (props?.markerPos)
      setMark({
        lng: props?.markerPos[1] || 0,
        lat: props?.markerPos[0] || 0,
      });
    setCenter({
      lng: props?.markerPos[1] || 31,
      lat: props?.markerPos[0] || 34,
    });
  }, [props?.markerPos]);

  const handleSelect = (place) => {
    if (!mapRef.current || !place?.value?.place_id) return;

    const placesService = new window.google.maps.places.PlacesService(
      mapRef.current
    );

    console.log(
      "[SimpleMap]<handleSelect>place.value.place_id",
      place.value.place_id,
      mapRef.current
    );

    try {
      // Fetch details for the selected place
      const res = placesService.getDetails(
        {
          placeId: place.value.place_id, // Use the place_id provided by the autocomplete
          fields: ["geometry", "name"],
        },
        (result, status) => {
          if (status === window?.google?.maps?.places?.PlacesServiceStatus.OK) {
            const location = result.geometry.location;
            const lat = location.lat();
            const lng = location.lng();

            console.log("[SimpleMap]<handleSelect>result", lat, lng);
            setMark({ lat, lng });
            setCenter({ lat, lng });
            props?.setData([lng, lat]);
          } else {
            console.error("Error fetching details:", status);
          }
        }
      );
    } catch (err) {
      console.error("Error fetching place details:", err);
    }
    // console.log("[SimpleMap]<handleSelect>res", res);

    setSearchValue(place); // Update selected place in state
  };

  const clearSearch = () => {
    setSearchValue(null); // Clear the search value
  };

  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance; // Store the map instance
  };

  const getMapOptions = (maps) => {
    return {
      disableDefaultUI: false,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    };
  };

  return Object.values(mark).length > 0 || props?.edit ? (
    <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
      <div style={{ position: "relative", height: "100%" }}>
        {props?.edit && (
          <div
            style={{
              position: "absolute",
              top: 40,
              left: 10,
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              width: "80%",
              background: "white",
              borderRadius: "8px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <GooglePlacesAutocomplete
                apiKey={API_KEY}
                selectProps={{
                  value: searchValue,
                  onChange: handleSelect,
                  placeholder: "Search for a location...",
                }}
              />
            </div>
            {searchValue && (
              <button
                onClick={clearSearch}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: "0 10px",
                }}
              >
                ✖
              </button>
            )}
          </div>
        )}
        <GoogleMap
          id="map"
          onLoad={onMapLoad} // Use onLoad to get the map instance
          onClick={(ev) => {
            if (!props?.edit) {
              return;
            }
            const latlang = ev?.latLng;
            const lat = latlang.lat();
            const lng = latlang.lng();
            props?.setData([lng, lat]);
            setMark({ lng, lat });
            setCenter({
              lng: lng || 31,
              lat: lat || 34,
            });
          }}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={getMapOptions}
          center={center}
          zoom={9}
        >
          {Object.values(mark).length > 0 && (
            <Marker {...mark} name={props?.label || "My Marker"} color="red" />
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  ) : null;
}
